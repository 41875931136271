import { Box, MaxWidth, Text } from "@sqymagma/elements"
import { motion } from "framer-motion"
import React from "react"
import { Orbits02 } from "../IconSystem"
import { CenterBox } from "../styles"

const QuoteBoxed = ({ children }) => {
  return (
    <CenterBox
      zIndex="1"
      as="section"
      height={{ default: "400px", m: "600px" }}
      position="relative"
      width="100vw"
      mb="xxl"
      mt="xxl"
      bg="grey"
      overflow="hidden"
    >
      <MaxWidth
        overflow="visible"
        css={`
          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            width: 100vw;
            background: ${p => p.theme.gradient.bannerBackground};
            background-size: cover;
            overflow: hidden;
          }
        `}
      >
        <Box
          as="div"
          display="flex"
          position="absolute"
          width="100vw"
          left="50%"
          top="0"
          bottom="0"
        >
          <motion.div style={{ scale: 1, x: "-50%" }}>
            <Orbits02 minHeight="100%" width="100vw" minWidth="500px"></Orbits02>
          </motion.div>
        </Box>
        <Text
          zIndex="1"
          textStyle="quote02"
          color="textOnInverse03"
          width={{ default: "100%", m: 9 / 12 }}
          textAlign="center"
          m="0 auto"
          maxWidth={{ default: "640px", m: "960px" }}
        >
          {children}
        </Text>
      </MaxWidth>
    </CenterBox>
  )
}

export default QuoteBoxed
