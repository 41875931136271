import React from "react"
import { Box, Text } from "@sqymagma/elements"
import Icon, { Arrow } from "../IconSystem"
import SiteLink from "./SiteLink"
import Line from "./Line"

const StickySidebar = ({
  goBackLink,
  goBackLabel,
  noSharingOptions,
  href,
  description,
  ...props
}) => {
  return (
    <Box width={3 / 12} pt="xs" pb="xl" pr="l" display="table" {...props}>
      <Box>
        <SiteLink
          to={goBackLink || "/"}
          textStyle="smallTightBold"
          caps
          color="text02"
          display="flex"
          alignItems="baseline"
        >
          <Arrow
            height="12px"
            css={`
              transform: rotate(180deg);
            `}
            fill="icon01"
            mr="xxs"
          />
          {goBackLabel || "Volver a inicio"}
        </SiteLink>
        {!noSharingOptions && (
          <>
            <Line py="s" maxWidth="180px" />
            <Text as="p" textStyle="smallTightBold" color="text02" mb="s" caps>
              Compartir:
            </Text>
            <Box as="ul">
              <SocialShareLink
                icon="twitter"
                url={`http://twitter.com/share?text=${description}&url=${href}&hashtags=observatorio,educacionSuperior,universidad`}
              >
                Twitter
              </SocialShareLink>
              <SocialShareLink
                icon="linkedin"
                url={`https://www.linkedin.com/sharing/share-offsite/?url=${href}`}
              >
                LinkedIn
              </SocialShareLink>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

const SocialShareLink = ({ children, icon, url }) => (
  <Text as="li">
    <Text
      as="a"
      href={url}
      target="_blank"
      textStyle="bodyTight"
      display="flex"
      alignItems="baseline"
      color="text02"
      mb="xs"
      css={`
        text-decoration: none;
      `}
    >
      <Icon name={icon} height="16px" fill="icon01" mr="xs" />
      {children}
    </Text>
  </Text>
)

export default StickySidebar
