import { graphql, useStaticQuery } from "gatsby"

const useTrends = slug => {
  const data = useStaticQuery(trendsQuery)

  const formattedData = data.trends.nodes.map(trend => ({
    ...trend.frontmatter,
    image: trend.frontmatter.image.sharp.fluid,
    body: trend.body,
  }))

  return formattedData.filter(el => {
    return el.slug === slug
  })[0]
}

export default useTrends

export const trendsQuery = graphql`
  query {
    trends: allMdx(filter: { fileAbsolutePath: { regex: "/tendencias/" } }) {
      nodes {
        frontmatter {
          title
          lead
          slug
          number
          socialgraphImage
          socialgraphDescription
          imageAlt
          image {
            sharp: childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body
      }
    }
  }
`
