// Component to render MDX of trends with the site custom style
// Used in TrendDetail.js

import React from "react"
import { MaxWidth, Text, Flex, Box } from "@sqymagma/elements"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Bold from "./Bold"
import StickySidebar from "./StickySidebar"
import QuoteBoxed from "./QuoteBoxed"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Title = ({ children }) => (
  <MaxWidth display="flex" alignItems="flex-end">
    <Text
      as="h2"
      width={{ default: "100%", m: 8 / 12 }}
      textStyle="title05"
      mt={{ default: "s", m: "m" }}
      mb="s"
    >
      {children}
    </Text>
  </MaxWidth>
)
const Paragraph = ({ children }) => (
  <MaxWidth as="p" display="flex" alignItems="flex-end">
    <Text
      as="span"
      textStyle="body"
      width={{ default: "100%", m: 8 / 12 }}
      pr={{ default: 0, l: `${(100 / 12) * 1}%` }}
      mb={{ default: "s" }}
    >
      {children}
    </Text>
  </MaxWidth>
)
const MdxQuote = ({ children }) => (
  <MaxWidth
    display="flex"
    justifyContent="flex-end"
    ml={{ default: "m", s: "xl", l: `${(100 / 12) * -0.5}%` }}
  >
    <Text
      className="quote"
      as="blockquote"
      textStyle="quote01"
      display="flex"
      justifyContent="flex-end"
      color="text02"
      ml="auto"
      width={{ default: "100%", s: "70%", l: 9 / 12 }}
      pr={{ default: 0, xs: "m", l: `${(100 / 12) * 1}%` }}
      py={{ default: "l", xs: "xl", m: "3xl" }}
      css={`
        * {
          margin: 0;
          padding: 0;
          width: 100%;
          max-width: 100%;
          ${p => p.theme.textStyle.quote01};
        }
      `}
    >
      {children}
    </Text>
  </MaxWidth>
)

const MdxList = ({ children }) => (
  <MaxWidth display="flex" alignItems="flex-end" mb={{ default: "s", m: "l" }}>
    <Text as="ul" width={{ default: "100%", m: 8 / 12 }} pr={`${(100 / 12) * 1}%`}>
      {children}
    </Text>
  </MaxWidth>
)

const MdxListItem = ({ children }) => (
  <Text
    as="li"
    width="100%"
    mb="s"
    ml={{ default: 0, m: "l" }}
    display="flex"
    textStyle="body"
    css={`
      &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-table;
        background: ${p => p.theme.color.icon02};
        margin-right: ${p => p.theme.spacing.xs};
        margin-top: 11px;
      }
      *,
      p > span {
        margin: 0;
        padding: 0;
        width: auto;
      }
    `}
  >
    <span>{children}</span>
  </Text>
)

const Italic = ({ children }) => (
  <Text as="em" textStyle="bodyItalic">
    {children}
  </Text>
)

const Wrapper = ({ children, href, description }) => {
  const breakpoints = useBreakpoint()
  return (
    <Flex flexDirection="column" maxWidth="100vw">
      <MaxWidth position="sticky" top="32px">
        {breakpoints.m && (
          <StickySidebar
            height="200px"
            goBackLabel="Volver a Trends"
            goBackLink="/trends-2020"
            href={href}
            description={description}
          />
        )}
      </MaxWidth>
      <Box mt={{ default: 0, m: "-200px" }}>{children}</Box>
    </Flex>
  )
}

const shortcodes = {
  strong: Bold,
  blockquote: MdxQuote,
  h2: Title,
  p: Paragraph,
  ul: MdxList,
  li: MdxListItem,
  ol: MdxList,
  em: Italic,
  QuoteBoxed: QuoteBoxed,
  wrapper: Wrapper,
}

const SiteMdxRenderer = ({ children, href, description }) => (
  <MDXProvider components={shortcodes}>
    <MDXRenderer href={href} description={description}>
      {children}
    </MDXRenderer>
  </MDXProvider>
)

export default SiteMdxRenderer
