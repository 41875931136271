import React from "react"
import { Text, Stack, Flex, Box } from "@sqymagma/elements"
import Layout from "./Layout"
import useTrend from "../hooks/useTrend"
import HeroContainer from "./HeroContainer"
import SiteMdxRenderer from "./SiteMdxRenderer"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import RelatedContent from "./RelatedContent"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const StyledBgdImage = styled(BackgroundImage)`
  background-size: cover;
  overflow-x: hidden;
`

const TrendDetail = ({ pageContext: { slug, description }, location: { href } }) => {
  const trend = useTrend(slug)
  const {
    title,
    lead,
    number,
    body,
    image,
    imageAlt,
    socialgraphImage,
    socialgraphDescription,
  } = trend
  const breakpoints = useBreakpoint()

  return (
    <Layout
      seoTitle={title}
      seoDescription={socialgraphDescription}
      seoImage={socialgraphImage}
      seoImageAlt={imageAlt}
    >
      <HeroContainer as="section" pb="3xl" withDecoration>
        <TrendDetailHeader title={title} number={number} lead={lead} />
      </HeroContainer>
      <StyledBgdImage fluid={image} alt={imageAlt}>
        <Box height={{ default: "300px", m: "500px" }} />
      </StyledBgdImage>

      <Flex
        pt={{ default: "xl", m: "4xl" }}
        pb="xl"
        as="article"
        position="relative"
        justifyContent="center"
      >
        <SiteMdxRenderer href={href} description={description}>
          {body}
        </SiteMdxRenderer>
      </Flex>

      <RelatedContent cols={breakpoints.s ? 4 : 3} currentSlug={slug} />
    </Layout>
  )
}

export default TrendDetail

const TrendDetailHeader = ({ title, lead, number }) => (
  <Stack width={{ default: 1, m: 9 / 12 }} vs={{ default: "s", m: "m" }} flexDirection="column">
    <Text as="p" textStyle="smallTightBold" color="text02" caps mb="0">
      {`Trend ${number}`}
    </Text>{" "}
    <Text as="h1" textStyle="display02" mb={{ default: "s", m: "0" }}>
      {title}
    </Text>
    <Text as="h2" textStyle="title04" mb="0">
      {lead}
    </Text>
  </Stack>
)
