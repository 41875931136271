import { Box, Text } from "@sqymagma/elements"
import React, { useEffect, useState } from "react"
import TrendsViewer from "./TrendsViewer"
import { shuffleArray } from "../utils"
import { TopLineContainer } from "../styles"
import { graphql, useStaticQuery } from "gatsby"

const RelatedContent = ({ cols, currentSlug }) => {
  const {
    trends: { nodes: data },
  } = useStaticQuery(trendsQuery)

  const [relatedTrends, setRelatedTrends] = useState([])
  const randomData = data ? shuffleArray(data) : []
  useEffect(
    () =>
      setRelatedTrends(
        randomData
          .map(trend => {
            return { ...trend.frontmatter, image: trend.frontmatter.image.sharp.fluid }
          })
          .filter(trend => {
            if (!currentSlug) return trend
            return trend.slug !== currentSlug
          })
      ),
    [currentSlug, setRelatedTrends]
  )

  return (
    <Box as="section" overflowX="hidden">
      <TopLineContainer>
        <Text as="h3" textStyle="smallBold" mb={{ default: "s", s: "l" }}>
          Contenidos relacionados
        </Text>
        <TrendsViewer
          content={relatedTrends.slice(0, cols)}
          cols={cols}
          py="s"
          pb={{ default: "l", m: "3xl" }}
        />
      </TopLineContainer>
    </Box>
  )
}

export default RelatedContent

export const trendsQuery = graphql`
  query {
    trends: allMdx(
      filter: { fileAbsolutePath: { regex: "/tendencias/" } }
      sort: { fields: frontmatter___number }
    ) {
      nodes {
        frontmatter {
          title
          lead
          slug
          number
          imageAlt
          image {
            sharp: childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
